import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import axios from "axios";
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'LoginPage',
    meta: {
      requireAuth: false,
    },
    component: function () {
      return import(/* webpackChunkName: "about" */ '../components/Login/LoginPage.vue')
    }
  },

  {
    path: '/appSidebar',
    name: 'appSidebar',
    component: () => import("../components/Common/appSidebar"),
    children: [


      {
        path: "/DashboardPage",
        name: "DashboardPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Dashboard/DashboardPage.vue"),
      },
      {
        path: "/appDashboard",
        name: "appDashboard",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Common/appDashboard"),
      },


      {
        path: "/PlansPage",
        name: "PlansPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/plans/PlansPage.vue"),
      },
      {
        path: "/PlanPresentationVidoePage",
        name: "PlanPresentationVidoePage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/plans/PlanPresentationVidoePage.vue"),
      },
     

      {
        path: "/IncompleteUsers",
        name: "IncompleteUsers",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/users/IncompleteUsers.vue"),
      },

      {
        path: "/ActiveUsersPage",
        name: "ActiveUsersPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/users/ActiveUsersPage.vue"),
      },
      {
        path: "/PendingUsersPage",
        name: "PendingUsersPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/users/PendingUsersPage.vue"),
      },
      {
        path: "/UserAprovalPage",
        name: "UserAprovalPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/users/UserAprovalPage.vue"),
      },
      {
        path: "/RejectedUsersPage",
        name: "RejectedUsersPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/users/RejectedUsersPage.vue"),
      },
      {
        path: "/DetailedViewPage",
        name: "DetailedViewPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/users/DetailedViewPage.vue"),
      },
      {
        path: "/DetailedViewPage2",
        name: "DetailedViewPage2",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/users/DetailedViewPage2.vue"),
      },
      {
        path: "/DetailedViewPage22",
        name: "DetailedViewPage22",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/users/DetailedViewPage1.vue"),
      },
      {
        path: "/DetailedViewPage3",
        name: "DetailedViewPage3",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/users/DetailedViewPage3.vue"),
      },

      {
        path: "/categoryPage",
        name: "categoryPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/category/categoryPage.vue"),
      },
      {
        path: "/TermsAndConditionPage",
        name: "TermsAndConditionPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Privacy/TermsAndConditionPage.vue"),
      },
      {
        path: "/PrivacyPolicyPage",
        name: "PrivacyPolicyPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Privacy/PrivacyPolicyPage.vue"),
      },

      {
        path: "/CampaignPage",
        name: "CampaignPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Campaign/CampaignPage.vue"),
      },

      {
        path: "/StoreManagerPage",
        name: "StoreManagerPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/StoreManager/StoreManagerPage.vue"),
      },


      {
        path: "/ProductCategoryPage",
        name: "ProductCategoryPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Products/ProductCategoryPage.vue"),
      },
      {
        path: "/ProductTypePage",
        name: "ProductTypePage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Products/ProductTypePage.vue"),
      },


      {
        path: "/ProductDetailedView",
        name: "ProductDetailedView",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Products/ProductDetailedView.vue"),
      },

      {
        path: "/PurchasePage",
        name: "PurchasePage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Products/PurchasePage.vue"),
      },


      {
        path: "/PurchaseDetailedView",
        name: "PurchaseDetailedView",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Products/PurchaseDetailedView.vue"),
      },
      {
        path: "/ProductsPage",
        name: "ProductsPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Products/ProductsPage.vue"),
      },
      {
        path: "/meetingSettings",
        name: "meetingSettings",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/meeting/meetingSettings.vue"),
      },
      {
        path: "/zoom",
        name: "zoom",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/zoom/ZoomMeting.vue"),
      },
       {
        path: "/meetingUsers",
        name: "meetingUsers",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/meeting/meetingUsers.vue"),
      },
      {
        path: "/CampaignIntroVideoPage",
        name: "CampaignIntroVideoPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Campaign/CampaignIntroVideoPage.vue"),
      },
      {
        path: "/CampaignDetailedView",
        name: "CampaignDetailedView",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Campaign/CampaignDetailedView.vue"),
      },
      {
        path: "/PromoCodePage",
        name: "PromoCodePage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/PromoCode/PromoCodePage.vue"),
      },
      {
        path: "/ParticipantsDetailedViewPage",
        name: "ParticipantsDetailedViewPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Campaign/ParticipantsDetailedViewPage.vue"),
      },
      {
        path: "/TrainingVideoPage",
        name: "TrainingVideoPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Training/TrainingVideoPage.vue"),
      },

      {
        path: "/Delivery",
        name: "Delivery",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/Products/Delivery.vue"),
      },
      {
        path: "/StcPage",
        name: "StcPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/UplineUsers/StcPage.vue"),
      },
      {
        path: "/RtcPage",
        name: "RtcPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/UplineUsers/RtcPage.vue"),
      },
      {
        path: "/NtcPage",
        name: "NtcPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/UplineUsers/NtcPage.vue"),
      },
      {
        path: "/SalesAnalysisPage",
        name: "SalesAnalysisPage",
        meta: {
          requireAuth: true,
        },
        component: () => import("../components/SalesAnalysis/SalesAnalysisPage.vue"),
      },
    ]
  },
  {
    path: "/ServerError",
    name: "ServerError",
    props: true,
    component: () => import("../components/Common/500"),
  },
  {
    path: "*",
    name: "404PageNotFound",
    props: true,
    meta: {
      requiresAuth: false,
    },
    component: () => import("../components/Common/404"),
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
})
  
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((route) => route.meta.requireAuth == true) &&
    store.state.isLoggedIn == false
  ) {
    console.log(from);
    next({ name: "LoginPage", params: { lastPage: from } });
    return;
  }
  if (store.state.isLoggedIn == true) {
    axios({
      method: "POST",
      url: "/authenticate/admin",
      headers: {
        "token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        var role = response.data.role;
        store.commit("role", response.data.role)
        if (!response.data.status) {
          store.commit("sessionOut", true);
          return;
        }
      })
      .catch((err) => {
        var msg = err;
        console.log(msg);
      });
  }
  if (to.name == "LoginPage" && store.state.isLoggedIn == true) {
    next({ name: "DashboardPage" });
    return;
  }
  next();
});
export default router
