
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueApexCharts from 'vue-apexcharts'

import vuetify from './plugins/vuetify'
import { VueEditor } from "vue2-editor";
Vue.component('apexchart', VueApexCharts)
Vue.component(VueEditor);
import axios from 'axios'
import VueElementLoading from "vue-element-loading";
Vue.component("VueElementLoading", VueElementLoading);    
   
axios.defaults.baseURL = 'https://api.onlineole.com/';
Vue.prototype.baseURL = "https://api.onlineole.com/"
Vue.prototype.mediaURL = "https://api.onlineole.com/"
Vue.prototype.mediaURLnew = "https://api.onlineole.com/wp/"

// window.axios = require('axios')
// axios.defaults.baseURL = 'http://192.168.54.130:5300';
// Vue.prototype.baseURL = "http://192.168.54.130:5300"
// Vue.prototype.mediaURL = "http://192.168.54.130:5300/u/"
// Vue.prototype.mediaURLnew = "http://192.168.54.130:5300/wp/"

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')




















